import React from 'react'
import { Link } from 'gatsby'
import { PropTypes } from 'prop-types'

class BlogArticle extends React.Component {
    render(){
        const regex = new RegExp('[ ,]', 'g')
        return <article className='p-3 h-100 d-flex flex-column'>
            <h2 className='mb-0 h4 qs-article-item__title'>
                {this.props.thumbnail && <div className='qs-article-item__thumbnail'>
                    <img src={this.props.thumbnail} className='qs-article-item__image' width={40} height={40} alt={`${this.props.title} Topic Icon`}/>
                </div>} 
                <span>{this.props.title}</span>
            </h2>
            <div className='qs-article-item__meta'>
                <div>{this.props.createdDate} </div>
                <div>Tags: 
                    {this.props.topic && <Link className={'qs-article-item__tag'} to={`/${this.props.slug}/${this.props.topic.topicName.toLowerCase().replace(regex, '-')}`}>{this.props.topic.topicName}</Link>}
                    {this.props.topicCategory && <Link className={'qs-article-item__tag'} to={`/${this.props.slug}/${this.props.topicCategory.topicCategory.toLowerCase().replace(regex, '-')}`}>{this.props.topicCategory.topicCategory}</Link>}
                </div>
            </div>
            <hr className='w-100'/>
            <p className='qs-article-item__description'>{this.props.description}</p>
            <button className='btn btn-outline-primary mt-auto qs-article-item__action'>Read notes</button>
        </article>
    }
}

BlogArticle.propTypes = {
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    createdDate: PropTypes.string,
    tags: PropTypes.array,
    description: PropTypes.string,
    slug: PropTypes.string,
    topic: PropTypes.obj,
    topicCategory: PropTypes.obj

}

export default BlogArticle
